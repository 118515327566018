.footer {
   position: relative;
   display: grid;
   align-items: center;
   justify-items: center;
   background-color: #252525;
   padding: 20px;

   &::after {
      content: "";
      position: absolute;
      bottom: 115px;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #ffffff22;
   }

   &__content {
      display: grid;
      grid-template: 1fr / repeat(3, minmax(230px, 300px));
      align-items: center;
      justify-items: center;
      gap: 20px;
      padding-bottom: 20px;
      margin-bottom: 20px;

      @media (max-width: 768px) {
         grid-template: repeat(3, auto) / 1fr;
         gap: 0;
      }
   }

   &__item {
      width: 245px;
      display: grid;
      align-items: center;
      justify-items: center;
      @media (max-width: 768px) {
         margin: 0 0 20px 0;
      }
   }

   &__title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
      @media (max-width: 768px) {
         margin-bottom: 5px;
      }
   }

   &__text {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      a {
         color: #999999;
      }
   }

   &__bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
   }

   &__logo {
      width: 50px;
      height: auto;
      margin-bottom: 10px;
   }

   &__copyright {
      font-size: 12px;
      color: #737373;
   }
}
