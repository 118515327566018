.header {
   min-width: 100%;
   margin: 0 auto;
   position: sticky;
   top: 0;
   z-index: 3;
   display: grid;
   justify-items: center;
   align-items: center;
   border-bottom: 3px solid #ffffff22;

   &__container {
      min-width: 78%;
      margin: 0 20px;
      display: grid;
      justify-content: space-between;
      grid-template-columns: 130px 1fr;
      @media (max-width: 931px) {
         width: 95%;
      }
   }

   &__logo {
      margin: 10px 0;
   }

   &__img {
      width: 10vw;
      max-width: 88px;
      min-width: 55px;
   }

   &__menu {
      display: grid;
      justify-content: end;
      align-items: center;
   }

   &__list {
      display: grid;
      align-items: center;
      justify-items: end;
      grid-template: 50px / repeat(5, minmax(110px, auto));

      & li {
         max-width: 100%;
      }

      @media (max-width: 931px) {
         display: grid;
         grid-template: repeat(5, 1fr) / 1fr;
         justify-items: center;
         align-items: center;
         width: 97.5%;
         height: 370px;
         position: absolute;
         top: 0%;
         left: 0%;
         transform: translateY(-100%);
         background-color: rgba(37, 37, 37, 0.9);
         padding: 10px;
         box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
         opacity: 0;
         visibility: hidden;
         transition:
            opacity 0.3s ease,
            visibility 0.3s ease,
            transform 0.5s ease;

         &.active {
            opacity: 1;
            visibility: visible;
            transform: translateY(0%);
         }
      }
   }

   &__link {
      font-family: "Montserrat Alternates", sans-serif;
      font-weight: 500;
      color: white;
      letter-spacing: 0;
      font-size: 20px;
      margin: 0 0 0 35px;
      transition: 0.7s;
      @media (max-width: 931px) {
         margin: 0;
      }

      &:hover {
         color: #fff500;
      }

      @media (max-width: 931px) {
         transition: 0.1s;

         &:hover {
            color: white;
         }

         &:active {
            color: #fff500;
         }
      }
   }

   &__burger {
      display: none;

      &.active:before {
         transform: rotate(45deg);
         top: 6px;
      }

      &.active:after {
         transform: rotate(-45deg);
         bottom: 7px;
      }

      &.active span {
         transform: scale(0);
      }

      span {
         position: absolute;
         background-color: white;
         width: 80%;
         height: 1.5px;
         right: 0;
         top: 7px;
         transition: all 0.7s ease 0s;
      }

      &:before {
         content: "";
         background-color: white;
         position: absolute;
         width: 100%;
         height: 2.5px;
         right: 0;
         top: 0;
         transition: all 0.7s ease 0s;
      }

      &:after {
         content: "";
         background-color: white;
         position: absolute;
         width: 100%;
         height: 2.5px;
         right: 0;
         bottom: 0;
         transition: all 0.7s ease 0s;
      }

      @media (max-width: 931px) {
         display: block;
         position: relative;
         width: 22px;
         height: 16px;
      }
   }
}
