:root {
   --index: calc(1vw + 1vh);
}

.main {
   min-width: 100%;
   margin: 0 auto;
   display: grid;
   align-items: start;
   justify-items: center;
   position: relative;
   z-index: 2;

   &__container {
      width: 78%;
      margin: 0 20px;
      @media (max-width: 931px) {
         width: 95%;
      }
   }
}
.hero {
   margin: 20px 0 0 0;

   &__content {
      display: grid;
      justify-items: center;
      align-items: center;
      grid-template: repeat(4, auto) / 1fr;
   }

   &__title {
      font-family: "Montserrat";
      font-size: calc(var(--index) * 3.2);
      font-weight: 500;
      letter-spacing: 0.1em;
      text-align: center;
      max-width: calc(var(--index) * 39);
      min-width: 200px;
      margin: 0 0 20px 0;
   }

   &__description {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: -0.2px;
      line-height: 26px;
      color: #999999;
      text-align: center;
      max-width: calc(var(--index) * 28);
      min-width: 200px;
      margin: 0 0 3em 0;
      @media (max-width: 931px) {
         max-width: 431px;
         line-height: 23px;
         margin: 0 0 1.7em 0;
      }
   }

   &__button {
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0.15em;
      color: #1a1a1a;
      background-color: #fff500;
      border-radius: 43px;
      padding: 2% 2.3%;
      margin: 0 0 3em 0;
      text-align: center;
      display: inline-block;
      transition: 0.3s;
      &:hover {
         color: #ffffff;
         background-color: #828282;
      }
      @media (max-width: 931px) {
         font-size: 14px;
         padding: 16px 18px;
         margin: 0 0 1.7em 0;

         transition: 0.1s;
         &:hover {
            color: #000000;
            background-color: #fff500;
         }
         &:active {
            color: #ffffff;
            background-color: #828282;
         }
      }
   }
   &__contacts {
      width: 100%;
      display: grid;
      justify-content: center;
      grid-template: 1fr / repeat(3, minmax(185px, 350px));
      @media (max-width: 610px) {
         // grid-template: repeat(3, minmax(80px, auto)) / 1fr;
         grid-template: 1fr / repeat(3, minmax(80px, 200px));
         @media (max-width: 500px) {
            grid-template: 1fr 1fr / 1fr 1fr;
            row-gap: 20px;
         }
      }
   }

   &__contacts-item {
      display: grid;
      align-items: center;
      justify-items: center;
      grid-template: repeat(3, auto) / 1fr;
      @media (max-width: 500px) {
         &.tel {
            grid-column: 1 / 3;
         }
      }
   }

   &__contacts-icon {
      text-align: center;
      width: 45px;
      height: 45px;
      @media (max-width: 610px) {
         width: 25px;
         height: 25px;
         & svg {
            width: 25px;
            height: 25px;
         }
         @media (max-width: 500px) {
            &.tel {
               margin: 0 0 5px 0;
            }
         }
      }
   }

   &__contacts-text {
      font-size: 18px;
      font-weight: 500;
      margin: 0 0 10px 0;
      & a {
         color: #ffffff;
      }
      @media (max-width: 610px) {
         font-size: 14px;
         margin: 0 0 5px 0;
      }
   }

   &__contacts-subtext {
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      margin: 0 0 10px 0;
      @media (max-width: 610px) {
         font-size: 12px;
      }
   }
}
.about {
   margin: 0 0 6em 0;
   position: relative;
   top: 0;
   left: 0;
   &__content {
      display: grid;
      align-items: center;
      justify-items: center;
      grid-template: 200px 1fr / 1fr;
      @media (max-width: 610px) {
         grid-template: 150px 1fr / 1fr;
      }
   }

   &__title {
      font-size: 36px;
      font-weight: 600;
      letter-spacing: 0.1em;
      position: relative;
      &::before {
         content: "";
         display: inline-block;
         width: 5px;
         height: 75px;
         background-color: #fff500;
         position: absolute;
         left: -20px;
         top: -15px;
      }
      @media (max-width: 610px) {
         font-size: 24px;
         &::before {
            width: 3px;
            height: 55px;
            left: -20px;
            top: -15px;
         }
      }
   }

   &__items {
      width: 100%;
      display: grid;
      align-items: center;
      justify-items: center;
      grid-template: 1fr / repeat(4, 1fr);
      @media (max-width: 1366px) {
         width: 570px;
         grid-template: 1fr 1fr / repeat(2, 1fr);
         row-gap: 30px;
         @media (max-width: 610px) {
            width: 100%;
            grid-template: repeat(4, 1fr) / 1fr;
            row-gap: 30px;
         }
      }
   }

   &__item {
      display: grid;
      align-items: start;
      justify-items: center;
      grid-template: auto 1fr / 1fr;
      width: 205px;
      height: 156px;
      padding: 25px;
      border-radius: 15px;
      background-color: #252525;
      transition: 0.7s;
      &:hover {
         background-color: #fff500;
         color: #191919;
         .about__item-text {
            color: #191919;
         }
         .about__item-title {
            &::before {
               background-color: #191919;
            }
         }
      }
   }

   &__item-title {
      justify-self: start;
      position: relative;
      font-size: 22px;
      font-weight: 400;
      letter-spacing: -0.28px;
      &::before {
         content: "";
         display: inline-block;
         width: 56px;
         height: 2px;
         background-color: #fff500;
         position: absolute;
         left: 0px;
         top: 70px;
         transform: translateY(-50%);
      }
   }

   &__item-text {
      padding: 30px 0 0 0;
      font-size: 16px;
      letter-spacing: -0.16px;
      color: #999999;
   }
}
.projects {
   display: grid;
   align-items: center;
   justify-items: center;
   grid-template: 150px auto auto / 1fr;
   background-color: #252525;
   border-radius: 10px;
   padding: 20px;

   @media (max-width: 610px) {
      grid-template: 100px 1fr / 1fr;
   }

   &__title {
      font-size: 36px;
      font-weight: 600;
      letter-spacing: 0.1em;
      position: relative;
      color: #fff;

      &::before {
         content: "";
         display: inline-block;
         width: 5px;
         height: 75px;
         background-color: #fff500;
         position: absolute;
         left: -20px;
         top: -15px;
      }

      @media (max-width: 610px) {
         font-size: 24px;

         &::before {
            width: 3px;
            height: 55px;
            left: -20px;
            top: -15px;
         }
      }
   }

   &__tabs {
      display: grid;
      grid-template: 1fr 1fr / repeat(5, 1fr);
      margin: 0 0 20.5px 0;

      @media (max-width: 1173px) {
         grid-template: 1fr 1fr / repeat(3, 1fr);
      }

      @media (max-width: 625px) {
         display: none;
      }
   }

   &__tab {
      font-family: "Roboto";
      font-weight: 700;
      color: #eaeaea;
      letter-spacing: 0.1em;
      border-radius: 15px;
      padding: 10px 20px;
      margin: 5px 10px;
      cursor: pointer;
      background-color: #3d3d3d00;
      border: 2px solid #3d3d3d;
      transition:
         background-color 0.3s,
         color 0.3s;

      &--active {
         color: #2d2d2d;
         background-color: #fff500;
      }

      &:hover {
         background-color: #fff500;
         color: #2d2d2d;
      }
   }

   &__dropdown {
      display: none;
      position: relative;
      &::before {
         content: "";
         display: inline-block;
         width: 50px;
         height: 50px;
         background: url("./assets/caret-down.png") no-repeat center/contain;
         position: absolute;
         right: 20px;
         top: 50%;
         z-index: 6;
         transform: translateY(-50%) rotate(0deg);
         transition: transform 0.3s;
      }
      &--active::before {
         transform: translateY(-50%) rotate(180deg);
      }
      @media (max-width: 625px) {
         margin: 0 0 20.5px 0;
         display: grid;
         justify-items: center;
         width: 100%;
      }
   }

   &__dropdown-button {
      &--active {
         font-family: "Roboto", sans-serif;
         font-weight: 700;
         font-size: 14px;
         color: #eaeaea;
         letter-spacing: 0.1em;
         border-radius: 15px;
         padding: 10px 20px;
         margin: 5px 10px;
         cursor: pointer;
         background-color: #3d3d3d00;
         border: 2px solid #3d3d3d;
         color: #2d2d2d;
         background-color: #fff500;
         width: 195px;
      }
   }

   &__dropdown-content {
      display: none;
      border-radius: 10px;
      position: absolute;
      top: 0;
      left: 0px;
      background-color: #252525;
      z-index: 1;
      width: 100%;

      &--active {
         display: grid;
         align-items: center;
         justify-items: center;
         grid-template: 1fr 1fr / repeat(1, 1fr);
      }
   }

   &__dropdown-item {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      font-size: 14px;
      color: #eaeaea;
      letter-spacing: 0.1em;
      border-radius: 15px;
      padding: 10px 20px;
      margin: 5px 10px;
      cursor: pointer;
      background-color: #3d3d3d00;
      border: 2px solid #3d3d3d;
      transition:
         background-color 0.3s,
         color 0.3s;
      width: 195px;
   }

   &__carousel {
      width: 100%;
      max-width: 650px;
      margin: 0 0 20.5px 0;

      @media (max-width: 767px) {
         max-width: 450px;
      }

      @media (max-width: 547px) {
         max-width: 300px;
      }

      .slick-slide {
         display: flex;
         justify-content: center;

         img {
            border-radius: 15px;
            max-width: 100%;
            height: 400px;

            @media (max-width: 767px) {
               height: 250px;
            }

            @media (max-width: 547px) {
               height: 200px;
            }
         }
      }

      .slick-dots {
         bottom: 0px;

         li {
            margin: 0 5px;

            button::before {
               color: #fcfcfc;
            }
         }

         .slick-active button::before {
            color: #fff500 !important;
         }
      }

      .slick-arrow {
         &::before {
            color: #fff500;
         }
      }
   }

   &__overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      cursor: pointer;
   }

   &__overlay-image {
      max-width: 90%;
      max-height: 90%;
   }
}
.services {
   margin: 0 0 120px 0;
   display: grid;
   align-items: center;
   justify-items: center;
   grid-template: 200px 1fr / 1fr;
   @media (max-width: 610px) {
      grid-template: 150px 1fr / 1fr;
   }
   &__title {
      font-size: 36px;
      font-weight: 600;
      letter-spacing: 0.1em;
      position: relative;
      &::before {
         content: "";
         display: inline-block;
         width: 5px;
         height: 75px;
         background-color: #fff500;
         position: absolute;
         left: -20px;
         top: -15px;
      }
      @media (max-width: 610px) {
         font-size: 24px;
         &::before {
            width: 3px;
            height: 55px;
            left: -20px;
            top: -15px;
         }
      }
   }

   &__grid {
      display: grid;
      grid-template: repeat(4, 1fr) / repeat(3, minmax(300px, 348px));
      gap: 25px;
      @media (max-width: 1227px) {
         grid-template: repeat(6, 1fr) / repeat(2, minmax(300px, 348px));
      }
      @media (max-width: 666px) {
         width: 100%;
         overflow: scroll;
         grid-template: 1fr / repeat(12, 308px);
      }
   }
}
.service-card {
   display: grid;
   justify-items: start;
   grid-template: auto auto 1fr auto / 1fr;
   background-color: #252525;
   border-radius: 10px;

   &__image {
      width: 100%;
      height: 200px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
   }

   &__title {
      margin: 10px 20px;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.02em;
      color: #fff;
      @media (max-width: 666px) {
         font-size: 20px;
      }
   }

   &__description {
      font-size: 14px;
      letter-spacing: -0.016em;
      color: #85878c;
      margin-bottom: 20px;
      padding: 0 0 0 20px;

      li {
         list-style: circle;
         margin-bottom: 5px;
      }
   }

   &__button {
      align-self: end;
      justify-self: center;
      margin: 0 0 15px 0;
      display: inline-block;
      padding: 10px 0;
      width: 150px;
      height: 20px;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.1em;
      color: #1a1a1a;
      background-color: #fff500;
      border-radius: 43px;
      text-align: center;
      transition: 0.3s;
      @media (max-width: 666px) {
         height: 15px;
         padding: 10px 0;
         font-size: 12px;
      }
      &:hover {
         color: #ffffff;
         background-color: #828282;
      }
      @media (max-width: 931px) {
         transition: 0.1s;
         &:hover {
            color: #000000;
            background-color: #fff500;
         }
         &:active {
            color: #ffffff;
            background-color: #828282;
         }
      }
   }
}
.contact-form {
   height: 582px;
   padding: 0 5%;
   background-color: #252525;
   border-radius: 6px;
   display: grid;
   align-items: center;
   justify-items: center;
   grid-template: 200px 1fr 70px / 1fr;
   @media (max-width: 610px) {
      grid-template: 150px 1fr 7 0px / 1fr;
   }

   &__title {
      position: relative;
      max-width: 720px;
      min-width: auto;
      font-size: 24px;
      font-weight: 700;
      line-height: 50px;
      letter-spacing: 0.05em;
      text-align: center;
      &::after {
         content: "";
         display: block;
         width: 175px;
         height: 4px;
         position: absolute;
         top: 135px;
         left: 50%;
         transform: translate(-50%, 0);
         background-color: #fff500;
         margin: 0 auto;
      }

      @media (max-width: 610px) {
         font-size: 18px;
         line-height: 35px;
         &::after {
            width: 145px;
            top: 135px;
         }
      }
   }

   &__form {
      width: 500px;
      display: grid;
      gap: 44px;
      @media (max-width: 610px) {
         width: 100%;
      }
   }

   &__input-group {
      display: grid;
      gap: 0.5em;
   }

   &__input {
      height: 56px;
      background-color: inherit;
      padding: 0 10px;
      border: 2px solid #f0f0f0;
      border-radius: 10px;
      font-size: 18px;
      color: #ffffff;
      &::placeholder {
         color: #999999;
         font-size: 16px;
         font-weight: 400;
         letter-spacing: 0.1em;
      }
      @media (max-width: 610px) {
         font-size: 14px;
         &::placeholder {
            font-size: 16px;
         }
      }
   }

   &__submit {
      height: 56px;
      background-color: #fff500;
      border: none;
      border-radius: 10px;
      font-family: "Roboto";
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0.1em;
      color: #1a1a1a;
      text-align: center;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
         color: #ffffff;
         background-color: #828282;
      }
      @media (max-width: 931px) {
         transition: 0.1s;
         &:hover {
            color: #000000;
            background-color: #fff500;
         }
         &:active {
            color: #ffffff;
            background-color: #828282;
         }
      }
      @media (max-width: 610px) {
         font-size: 18px;
      }
   }

   &__privacy {
      align-self: start;
      width: 320px;
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      text-align: center;
      color: #a6a6a6;
      @media (max-width: 610px) {
         font-size: 14px;
      }
      a {
         color: #a6a6a6;
         border-bottom: 1px solid#a6a6a6;
         transition: 0.3s;
         &:hover {
            color: #fff500;
            border-bottom: 1px solid#fff500;
         }
      }
   }
}
.social {
   display: grid;
   align-items: center;
   justify-items: center;
   grid-template: 200px 1fr / 1fr;
   margin: 0 0 111px 0;
   @media (max-width: 610px) {
      grid-template: 150px 1fr / 1fr;
   }
   &__title {
      position: relative;
      font-size: 36px;
      font-weight: 600;
      letter-spacing: 0.1em;
      &::before {
         content: "";
         display: inline-block;
         width: 5px;
         height: 75px;
         background-color: #fff500;
         position: absolute;
         left: -20px;
         top: -15px;
      }
      @media (max-width: 610px) {
         font-size: 24px;
         &::before {
            width: 3px;
            height: 55px;
            left: -20px;
            top: -15px;
         }
      }
   }

   &__container {
      width: 100%;
      display: grid;
      justify-items: center;
      justify-content: center;
      grid-template: 1fr / repeat(2, minmax(auto, 600px));
      @media (max-width: 1035px) {
         grid-template: 1fr 1fr / 1fr;
         row-gap: 20px;
      }
   }

   &__card {
      width: 400px;
      height: 324px;
      background-color: #252525;
      padding: 20px;
      border-radius: 8px;
      @media (max-width: 1213px) {
         width: 350px;
         height: 290px;
         @media (max-width: 610px) {
            width: 80%;
            height: 290px;
         }
      }
   }

   &__header {
      display: grid;
      align-items: center;
      justify-items: start;
      grid-template: 1fr / 50px 1fr auto;
      padding: 0 10px;
      margin: 0 0 25px 0;
      .social__icon {
         width: 40px;
         height: auto;
      }
      .social__platform {
         font-size: 14px;
         font-weight: 400;
         color: #bfbfbf;
      }
      .social__button {
         font-family: "Roboto";
         font-size: 12px;
         letter-spacing: 0.2em;
         color: #737373;
         border-radius: 30px;
         background-color: transparent;
         border: 2px solid #303030;
         text-align: center;
         padding: 15px 0;
         width: 161px;
         cursor: pointer;
         transition: 0.3s;
         &:hover {
            color: #eaeaea;
            border: 2px solid #eaeaea;
         }
         @media (max-width: 610px) {
            font-size: 12px;
            padding: 12px 0;
            width: 141px;
         }
      }
   }

   &__images {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
      .social__image {
         width: 117px;
         height: 120px;
         border-radius: 5px;
         @media (max-width: 1213px) {
            width: 97px;
            height: 100px;
            @media (max-width: 610px) {
               width: 90%;
               height: 100px;
            }
         }
      }
   }

   &__content {
      font-size: 16px;
      line-height: 30px;
      color: #999999;
      & p {
         margin: 0 0 0 10px;
         width: 290px;
      }
   }
}
.modal {
   position: fixed;
   z-index: 6;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.8);
   display: flex;
   justify-content: center;
   align-items: center;

   &__content {
      max-width: 500px;
      min-width: 300px;
      height: auto;
      position: relative;
      z-index: 10;
      background: rgb(25, 25, 25);
      padding: 10px;
      margin: 0 25px;
      border-radius: 25px;
      text-align: center;
      & img {
         width: 100%;
         height: auto;
         border-radius: 25px;
      }
      & div {
         font-size: 20px;
         & img {
            width: 50%;
            height: auto;
            border-radius: 25px;
         }
      }
   }

   &__close {
      color: #eaeaea;
      background: none;
      border: none;
      font-size: 35px;
      position: absolute;
      top: 15px;
      right: 25px;
      cursor: pointer;
   }
}
